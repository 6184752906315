import React from 'react';
import styled from '@emotion/styled';
import { Scene, Room, Player } from '../../types';
import Avatar from '../Avatar';

export default function Answers(props: { scene: Scene; room: Room }) {
  const { scene, room } = props;

  const answerPlayersIndex: {
    [answer: string]: Player[];
  } = {
    '': [] as Player[],
  };

  const allPlayers = room.state.presences.flatMap((p) => {
    return p.players;
  });

  let correctAnswer: string = '';
  let answerIsImage = false;

  if (scene.prompt) {
    correctAnswer = scene.prompt.answers[0];
  } else if (scene.quizChoices) {
    correctAnswer = scene.quizChoices
      .filter((q) => q.isCorrect)
      .map((q) => {
        if (q.imageUrl) {
          answerIsImage = true;
          return q.imageUrl;
        }
        return q.label;
      })
      .join(', ');
  }

  // "(No answer)" default
  allPlayers.forEach((player) => {
    answerPlayersIndex[''].push(player);
  });

  // Override with actual answers
  room.state.answers?.forEach((answer) => {
    if (answer.sceneId !== scene.id) return;

    const answerText = answer.answer || '';
    const player = allPlayers.find((p) => p.id === answer.playerId);

    if (!player) {
      return;
    }

    if (answerText) {
      answerPlayersIndex[answerText] = (
        answerPlayersIndex[answerText] || []
      ).concat(player);

      answerPlayersIndex[''] = answerPlayersIndex[''].filter(
        (p) => p.id !== player.id
      );
    }
  });

  if (answerPlayersIndex[''].length === 0) {
    delete answerPlayersIndex[''];
  }

  const showCorrectAnswer = !scene.directives.includes('HIDE_CORRECT_ANSWER');

  return (
    <Container>
      <p>Time is up! {showCorrectAnswer && 'The correct answer is...'}</p>
      {showCorrectAnswer ? (
        <p className="correct-answer">
          {answerIsImage ? <img src={correctAnswer} alt="" /> : correctAnswer}
        </p>
      ) : (
        <p>Let's take a look at everyone's answers.</p>
      )}
      <div className="answers">
        {Object.entries(answerPlayersIndex).map(([answerText, players]) => {
          return (
            <div className="answer" key={answerText}>
              <div className="players">
                {players.map((player) => {
                  return (
                    <Avatar
                      key={player.id}
                      src={player.avatarUrl}
                      name={player.name}
                    />
                  );
                })}
              </div>
              <p className="answer-text">{answerText || '(no answer)'}</p>
            </div>
          );
        })}
      </div>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #5685e2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .correct-answer {
    font-size: 48px;

    img {
      max-height: 200px;
    }
  }

  .answers {
    display: flex;
    justify-content: center;

    & > * {
      background: rgba(255, 255, 255, 0.1);
      padding: 20px 10px;
      border-radius: 30px;
      margin: 0 5px;
    }
  }

  .answer-text {
    position: relative;
    padding: 15px;
    background: #fff;
    color: #111;
    border-radius: 15px;
    font-size: 21px;
    display: inline-block;
    opacity: 0.8;
    min-width: 100px;
    margin: 25px 0 0 0;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0px;
      width: 20px;
      height: 20px;
      background: #fff;
      transform: rotate(45deg) translateX(-50%) translateY(-50%);
      transform-origin: top left;
    }
  }

  .players > * {
    margin: 0 10px;
  }
`;
