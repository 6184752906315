import React from 'react';
import styled from '@emotion/styled';
import { Scene, Room, Player } from '../../types';

export default function Answers(props: { scene: Scene; room: Room }) {
  const { scene, room } = props;

  const answerPlayersIndex: {
    [answer: string]: Player[];
  } = {
    '': [] as Player[],
  };

  const allPlayers = room.state.presences.flatMap((p) => {
    return p.players;
  });

  let correctAnswer: string = '';

  if (scene.prompt) {
    correctAnswer = scene.prompt.answers[0];
  } else if (scene.quizChoices) {
    correctAnswer = scene.quizChoices
      .filter((q) => q.isCorrect)
      .map((q) => q.label)
      .join(', ');
  }

  // "(No answer)" default
  allPlayers.forEach((player) => {
    answerPlayersIndex[''].push(player);
  });

  // Override with actual answers
  room.state.answers?.forEach((answer) => {
    if (answer.sceneId !== scene.id) return;

    const answerText = answer.answer || '';
    const player = allPlayers.find((p) => p.id === answer.playerId);

    if (!player) {
      return;
    }

    if (answerText) {
      answerPlayersIndex[answerText] = (
        answerPlayersIndex[answerText] || []
      ).concat(player);

      answerPlayersIndex[''] = answerPlayersIndex[''].filter(
        (p) => p.id !== player.id
      );
    }
  });

  if (answerPlayersIndex[''].length === 0) {
    delete answerPlayersIndex[''];
  }

  const showCorrectAnswer = !scene.directives.includes('HIDE_CORRECT_ANSWER');

  return (
    <Container>
      <p>Time is up! {showCorrectAnswer && 'The correct answer is...'}</p>
      {showCorrectAnswer ? (
        <p className="correct-answer">{correctAnswer}</p>
      ) : (
        <p>Let's take a look at everyone's answers.</p>
      )}
      <table className="answers">
        <tbody>
          {Object.entries(answerPlayersIndex).map(([answerText, players]) => {
            return (
              <tr>
                <td>{answerText || 'No answer'}</td>
                <td>
                  <div
                    className="answer-count-bar"
                    style={{
                      width: (players.length / allPlayers.length) * 100 + '%',
                    }}
                  />
                  <div className="answer-count">
                    {(players.length / allPlayers.length) * 100}% (
                    {players.length})
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: #5685e2;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .correct-answer {
    font-size: 48px;
  }

  .answers {
    border: 1px solid rgba(255, 255, 255, 0.5);
    min-width: 600px;
    max-width: 1024px;
    text-align: right;
    margin: 0 auto;

    td {
      padding: 0.5em;
    }
  }

  .answer-count {
    min-height: 20px;
    background: rgba(255, 255, 255, 0.25);
    padding: 0.5em;
  }

  .answer-text {
    position: relative;
    padding: 15px;
    background: #fff;
    color: #111;
    border-radius: 15px;
    font-size: 21px;
    display: inline-block;
    opacity: 0.8;
    min-width: 100px;
    margin: 25px 0 0 0;
  }

  .players > * {
    margin: 0 10px;
  }
`;
