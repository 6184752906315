export interface ConfigInput {
  [key: string]: string | undefined;
}

export const parseConfig = ({
  REACT_APP_API_URI = '',
  REACT_APP_WEBSOCKET_URI = '',
  REACT_APP_STRIPE_API_KEY = '',
  REACT_APP_AUTH0_STRIPE_ID_KEY = 'https://ludolo.com/stripe_customer_id',
  REACT_APP_AUTH0_STRIPE_SUB_KEY = 'https://ludolo.com/stripe_customer_subscribed',
  REACT_APP_AUTH0_CAMPAIGN_ID_KEY = 'https://ludolo.com/campaign_id',
  REACT_APP_CAMPAIGN_ID_PARAM = 'campaignId',
}: ConfigInput) => ({
  api: {
    uri: REACT_APP_API_URI,
    websocketUri: REACT_APP_WEBSOCKET_URI,
  },
  auth0: {
    clientId: '3WNR4t0qo0FtcH9n04CuXmCTwvPsuOiq',
    domain: 'ludolo.us.auth0.com',
    redirectUri: `${window.location.protocol}//${window.location.host}/auth/callback`,
    stripe: {
      idKey: REACT_APP_AUTH0_STRIPE_ID_KEY,
      subKey: REACT_APP_AUTH0_STRIPE_SUB_KEY,
    },
  },
  stripe: {
    apiKey: REACT_APP_STRIPE_API_KEY,
  },
  campaign: {
    idParam: REACT_APP_CAMPAIGN_ID_PARAM 
  }
});

export default parseConfig(process.env);
