import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Episode } from '../types';
import Button from './Button';
import { Link } from 'react-router-dom';
import { getRoomCreationPath } from '../lib/paths';
import { getRoundedDurationMean } from '../lib/episodes';
import analytics from '../lib/analytics';

export interface EpisodeGridProps {
  episodes: Episode[];
}

export default function EpisodeGrid({ episodes }: EpisodeGridProps) {
  const [state, setState] = useState<{
    selectedEpisode?: Episode;
  }>({});

  const viewEpisodeDetails = (selectedEpisode: Episode) => {
    // analytics.app.events.episode.detailsViewed({
    //   episodeId: selectedEpisode.id,
    // });

    setState({
      ...state,
      selectedEpisode,
    });
  };

  const handleDetailsClose = () => {
    setState({
      ...state,
      selectedEpisode: undefined,
    });
  };

  return (
    <>
      <GridContainer>
        {episodes.map((episode) => {
          const handleClick = () => {
            viewEpisodeDetails(episode);
          };

          return (
            <li
              className={episode.free ? 'free' : ''}
              key={episode.id}
              onClick={handleClick}
            >
              <div
                className="banner"
                style={{
                  backgroundImage: `url(${episode.display.web.bannerUrl})`,
                }}
              />
              <p className="name">{episode.name}</p>
              <p className="duration">
                {getRoundedDurationMean(episode, 5)} minutes
              </p>
            </li>
          );
        })}
      </GridContainer>
      <EpisodeDetails
        episode={state.selectedEpisode}
        onClose={handleDetailsClose}
      />
    </>
  );
}

const EpisodeDetails = (props: { episode?: Episode; onClose: () => any }) => {
  const [state, setState] = useState<{
    episode?: Episode;
  }>({});

  useEffect(() => {
    if (!props.episode || props.episode === state.episode) {
      return;
    }

    // Keeps a memory of previously shown episode
    setState({
      ...state,
      episode: props.episode,
    });
  }, [props.episode, state]);

  const { episode } = state;

  if (!episode) {
    return null;
  }

  return (
    <EpisodeDetailsContainer
      className={props.episode === state.episode ? 'show' : 'hide'}
    >
      <div className="clickout" onClick={props.onClose} />
      <div className="inner">
        <div
          className="banner"
          style={{
            backgroundImage: `url(${episode.display.web.bannerUrl})`,
          }}
        />
        <h2>{episode.name}</h2>
        <p>{episode.blurb}</p>
        <Link
          className="room-creation-link"
          to={getRoomCreationPath(episode.id)}
        >
          <Button>Start Episode</Button>
        </Link>
        <p className="small-screen-notice">
          Please visit on a tablet or desktop to run an episode. Mobile devices
          act as a controller!
        </p>
      </div>
    </EpisodeDetailsContainer>
  );
};

const GridContainer = styled.ul`
  list-style: none;

  li {
    display: flex;
    float: left;
    flex-direction: column;
    justify-content: center;
    width: calc(33% - 15px);
    min-width: 250px;
    height: 300px;
    padding: 30px;
    position: relative;
    background: #000;
    cursor: pointer;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    overflow: hidden;

    &:first-of-type {
      width: calc(66% - 15px);
      height: 615px;
      flex-grow: 1;
      flex-shrink: 0;
    }

    &.free::after {
      content: 'Free';
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 3px 6px;
      background: green;
      color: #fff;
      display: inline-block;
      border-radius: 3px;
    }

    .banner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition: all 0.2s linear;
      background-size: cover;
      background-position: center;
    }

    &:hover .banner {
      opacity: 0.8;
    }

    p {
      margin: 0;
      position: relative;
      z-index: 2;
      color: #fff;
      text-align: center;
      display: block;

      &.name {
        font-size: 24px;
        margin-bottom: 0.25em;
      }

      &.duration {
        font-size: 16px;
        font-weight: normal;
      }
    }
  }
`;

const EpisodeDetailsContainer = styled.div`
  position: relative;
  z-index: 9999;

  .clickout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &.hide {
    .clickout {
      display: none;
    }

    .inner {
      left: -100%;
      opacity: 0;
    }
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    z-index: 2;
    top: 50%;
    left: 50%;
    width: calc(100% - 30px);
    max-width: 900px;
    min-height: 400px;
    transform: translateX(-50%) translateY(-50%);
    background-color: #000;
    padding: clamp(10px, 15%, 60px);
    border-radius: 15px;
    transition: opacity 0.2s linear;
    opacity: 1;
    overflow: hidden;

    & > * {
      position: relative;
      z-index: 2;
    }

    .banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.4;
      z-index: 1;
      background-size: cover;
      background-position: center;
    }

    h2,
    p {
      position: relative;
      color: #fff;
      text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    }

    h2 {
      font-size: 48px;
      font-weight: 200;
      margin: 0 0 0.5em 0;
    }

    p {
      font-size: 21px;
      margin: 0;
      line-height: 1.5;
    }

    .room-creation-link {
      display: block;
      width: 300px;
      margin: 30px auto 0 auto;

      button {
        width: 100%;
        background: #00a3ff;
        color: #fff;
      }

      @media (max-width: 812px) {
        display: none;
      }
    }

    .small-screen-notice {
      background: #933;
      color: #fff;
      margin-top: 1.5em;
      padding: 0.5em;

      @media (min-width: 813px) {
        display: none;
      }
    }
  }
`;
