import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Episode, Player, StripeInfo } from './types';
import config from './config';
import analytics from './lib/analytics';

export const getStripeInfo = (user: any): StripeInfo => {
  return {
    id: user[config.auth0.stripe.idKey] as string | undefined,
    subscribed: Boolean(user[config.auth0.stripe.subKey]),
  };
};

export const useStripe = () => {
  const [stripe, setStripe] = useState<Stripe | null>(null);

  useEffect(() => {
    loadStripe(config.stripe.apiKey).then((stripe) => {
      setStripe(stripe);
    });
  }, []);

  return stripe;
};

export const useLoginAndReturn = () => {
  const { isLoading, user, loginWithRedirect } = useAuth0();

  return useCallback(() => {
    if (isLoading) {
      return;
    }

    if (user) {
      analytics.app.saveContext({
        userId: user.sub,
        userEmail: user.email,
        userName: user.name,
      });
      return;
    }

    localStorage.pathBeforeLogin = window.location.pathname;
    loginWithRedirect();
  }, [isLoading, user, loginWithRedirect]);
};

export const useRequiredUser = (): null | Player => {
  const { user } = useAuth0();
  const loginAndReturn = useLoginAndReturn();

  useEffect(() => {
    loginAndReturn();
  }, [loginAndReturn]);

  return user
    ? {
        id: user.sub as string,
        name: user.name as string,
        avatarUrl: user.picture as string,
        stripe: getStripeInfo(user),
      }
    : null;
};

export const useEpisode = (episode: Episode) => {
  const { loading, error, data } = useQuery<
    { episode: Episode },
    { episodeId: string }
  >(EPISODE_QUERY, {
    variables: {
      episodeId: episode.id,
    },
  });

  return {
    loading,
    error,
    data: data?.episode,
  };
};

export const useRoomMethods = (roomId: string) => {
  const [joinRoomMutation] = useMutation<
    { presence: { id: string; players: { id: string; name: string }[] } },
    { roomId: string; presenceId?: string; playerNames: string[] }
  >(JOIN_ROOM_MUTATION);

  const [activateRoomMutation] = useMutation<{}, { roomId: string }>(
    ACTIVATE_ROOM_MUTATION
  );

  const [exitRoomMutation] = useMutation<{}, { roomId: string }>(
    EXIT_ROOM_MUTATION
  );

  const [completeSceneMutation] = useMutation<
    {},
    { roomId: string; sceneIndex: number }
  >(COMPLETE_SCENE_MUTATION);

  const [skipToSceneMutation] = useMutation<
    {},
    { roomId: string; targetSceneIndex: number }
  >(SKIP_TO_SCENE_MUTATION);

  const [submitAnswerMutation] = useMutation<
    {},
    {
      roomId: string;
      playerIds: string[];
      answer: string;
      sceneId: string;
    }
  >(SUBMIT_ANSWER_MUTATION);

  return {
    submitAnswer: (variables: {
      playerIds: string[];
      answer: string;
      sceneId: string;
    }) => {
      return submitAnswerMutation({
        variables: {
          ...variables,
          roomId,
        },
      });
    },

    joinRoom: (variables: { playerNames: string[]; presenceId?: string }) => {
      return joinRoomMutation({
        variables: {
          ...variables,
          roomId,
        },
      });
    },

    activateRoom: () => {
      return activateRoomMutation({
        variables: {
          roomId,
        },
      });
    },

    exitRoom: () => {
      return exitRoomMutation({
        variables: {
          roomId,
        },
      });
    },

    completeScene: (sceneIndex: number) => {
      return completeSceneMutation({
        variables: {
          sceneIndex,
          roomId,
        },
      });
    },

    skipToScene: (targetSceneIndex: number) => {
      return skipToSceneMutation({
        variables: {
          targetSceneIndex,
          roomId,
        },
      });
    },
  };
};

const JOIN_ROOM_MUTATION = gql`
  mutation JoinRoom(
    $roomId: ID!
    $playerNames: [String!]!
    $presenceId: String
  ) {
    presence: joinRoom(
      input: {
        roomId: $roomId
        playerNames: $playerNames
        presenceId: $presenceId
      }
    ) {
      id
      players {
        id
        name
      }
    }
  }
`;

const SUBMIT_ANSWER_MUTATION = gql`
  mutation SubmitAnswer(
    $roomId: ID!
    $playerIds: [ID!]!
    $sceneId: ID!
    $answer: String!
  ) {
    roomState: submitAnswer(
      input: {
        roomId: $roomId
        playerIds: $playerIds
        sceneId: $sceneId
        answer: $answer
      }
    ) {
      phase
    }
  }
`;

const ACTIVATE_ROOM_MUTATION = gql`
  mutation ActivateRoom($roomId: ID!) {
    roomState: activateRoom(input: { roomId: $roomId }) {
      phase
    }
  }
`;

const EXIT_ROOM_MUTATION = gql`
  mutation ExitRoom($roomId: ID!) {
    roomState: exitRoom(input: { roomId: $roomId }) {
      phase
    }
  }
`;

const COMPLETE_SCENE_MUTATION = gql`
  mutation CompleteScene($roomId: ID!, $sceneIndex: Int!) {
    roomState: completeScene(
      input: { roomId: $roomId, sceneIndex: $sceneIndex }
    ) {
      sceneIndex
    }
  }
`;

const SKIP_TO_SCENE_MUTATION = gql`
  mutation SkipToScene($roomId: ID!, $targetSceneIndex: Int!) {
    roomState: skipToScene(
      input: { roomId: $roomId, targetSceneIndex: $targetSceneIndex }
    ) {
      sceneIndex
    }
  }
`;

const EPISODE_QUERY = gql`
  query Episode($episodeId: ID!) {
    episode(id: $episodeId) {
      idleBg {
        url
      }
      scenes {
        id
        name
        media {
          type
          url
        }
        background {
          type
          url
        }
        isSubscene
        showAnswers
        content
        secondaryContent
        advanceWithButton
        timer
        startDelay
        directives
        videoStart
        videoEnd
        prompt {
          text
          answers
        }
        quizChoices {
          label
          imageUrl
          isCorrect
        }
      }
    }
  }
`;
