import styled from '@emotion/styled';

export default styled.div`
  width: 100%;
  max-width: 400px;
  padding: 30px;
  margin: 60px auto;
  font-size: 21px;
  background: #eee;
  border: 2px solid #ccc;
  border-radius: 5px;
  text-align: center;

  a {
    display: inline-block;
    padding-bottom: 1px;
    text-decoration: none;
    color: #00c;
    border-bottom: 2px solid #00c;
  }
`;
