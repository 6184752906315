import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './GlobalStyles';
import App from './App';
import { ProviderWrapper } from './Providers';
import { interceptCampaignId } from './lib/campaign';
import config from './config';

interceptCampaignId() ||
  ReactDOM.render(
    <React.StrictMode>
      <GlobalStyles />
      <ProviderWrapper {...config.api}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ProviderWrapper>
    </React.StrictMode>,
    document.getElementById('root')
  );
