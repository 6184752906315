import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Route, Switch } from 'react-router-dom';
import EpisodeGrid from '../components/EpisodeGrid';
import { Episode } from '../types';
import CreateRoom from '../components/CreateRoom';
import { getRoomCreationPath } from '../lib/paths';
import SiteLayout from '../layouts/SiteLayout';
import Message from '../components/Message';

const EpisodesRoute = () => {
  return (
    <Switch>
      <Route path={getRoomCreationPath()}>
        <CreateRoom />
      </Route>
      <Route>
        <SiteLayout>
          <EpisodeListLoader />
        </SiteLayout>
      </Route>
    </Switch>
  );
};

const EpisodeListLoader = () => {
  const { loading, error, data } = useQuery<{
    episodes: Episode[];
  }>(EPISODES_QUERY);

  if (loading) {
    return <Message>Loading...</Message>;
  }

  if (error) {
    return <Message>{error.message}</Message>;
  }

  if (!data) {
    return <p>There was a problem loading episodes.</p>;
  }

  return <EpisodeGrid episodes={data.episodes} />;
};

const EPISODES_QUERY = gql`
  query {
    episodes {
      id
      name
      blurb
      free
      size {
        min
        max
      }
      display {
        web {
          bannerUrl
        }
      }
      scenes {
        timer
        videoStart
        videoEnd
      }
    }
  }
`;

export default EpisodesRoute;
