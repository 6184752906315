import React from 'react';
import styled from '@emotion/styled';
import EpisodeEngine from './EpisodeEngine';
import Lobby from './Lobby';
import { useLiveRoom } from './LiveRoomContext';

const LiveRoomRenderer = () => {
  const { room } = useLiveRoom();

  switch (room?.state.phase) {
    case 'PENDING':
      return <Lobby />;

    case 'ACTIVE':
      return <EpisodeEngine />;

    case 'ENDED':
      return (
        <Ended>
          This episode has finished playing in this room. Thank you for
          participating!
        </Ended>
      );

    default:
      return <p>Room is in unknown state.</p>;
  }
};

const Ended = styled.p`
  font-size: 36px;
  font-weight: 200;
`;

export default LiveRoomRenderer;
