import React, { PropsWithChildren, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { Link, Redirect } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';
import { useRequiredUser, useStripe } from '../hooks';

import {
  ApolloClient,
  ApolloProvider,
  gql,
  HttpLink,
  InMemoryCache,
  split,
  useMutation,
} from '@apollo/client';

import analytics from '../lib/analytics';
import { Player } from '../types';
import styled from '@emotion/styled';
import { getCampaignId } from '../lib/campaign';

export interface ApolloProviderWithAuthProps {
  uri: string;
  websocketUri: string;
  children: any;
}

export interface ApolloProviderWithAuthState {
  accessToken: undefined | string;
}

export class ApolloProviderWithAuth extends React.Component<
  ApolloProviderWithAuthProps,
  ApolloProviderWithAuthState
> {
  state: ApolloProviderWithAuthState = {
    accessToken: undefined,
  };

  setAccessToken = (accessToken: string | undefined) => {
    this.setState({
      accessToken,
    });
  };

  connectionId = uuid4();

  cache = new InMemoryCache();

  httpLink = new HttpLink({
    uri: this.props.uri,
  });

  authLink = setContext((_request, { headers }) => {
    const { accessToken } = this.state;

    return {
      headers: accessToken
        ? {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
          }
        : headers,
    };
  });

  createWsLink = () => {
    return new WebSocketLink({
      uri: this.props.websocketUri,
      lazy: true,
      options: {
        reconnect: true,
        connectionParams: () => {
          return {
            connectionId: this.connectionId,
            authToken: this.state.accessToken,
            userAgent: navigator.userAgent,
          };
        },
      },
    });
  };

  createSplitLink = () => {
    return split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      this.createWsLink(),
      this.authLink.concat(this.httpLink)
    );
  };

  createApolloClient = () => {
    return new ApolloClient({
      cache: this.cache,
      link: this.createSplitLink(),
    });
  };

  shouldComponentUpdate(
    _nextProps: any,
    nextState: ApolloProviderWithAuthState
  ) {
    return this.state.accessToken !== nextState.accessToken;
  }

  render() {
    const { accessToken } = this.state;

    if (accessToken === undefined) {
      return <AccessTokenLoader onLoad={this.setAccessToken} />;
    }

    return (
      <ApolloProvider client={this.createApolloClient()}>
        {this.props.children}
      </ApolloProvider>
    );
  }
}

export const AccessTokenLoader = ({
  onLoad,
}: PropsWithChildren<{
  onLoad: (accessToken: undefined | string) => any;
}>) => {
  const { getIdTokenClaims, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    getIdTokenClaims().then((claims) => {
      const token = claims?.__raw || '';
      onLoad(token);
    });
  }, [isLoading, getIdTokenClaims, onLoad]);

  return null;
};

export const RequireSubscription = ({ children }: PropsWithChildren<{}>) => {
  const user = useRequiredUser();

  return user ? (
    user.stripe.subscribed ? (
      <>{children}</>
    ) : (
      <Payment user={user} />
    )
  ) : null;
};

export const RequireLogin = ({ children }: PropsWithChildren<{}>) => {
  return useRequiredUser() ? <>{children}</> : null;
};

export const Payment = (props: { user: Player }) => {
  const stripe = useStripe();

  const [createPaymentSession] = useMutation<
    { session: { id: string } },
    {
      input: {
        successUrl: string;
        cancelUrl: string;
        campaignId: string | undefined;
      };
    }
  >(CREATE_PAYMENT_SESSION);

  if (!stripe) {
    return null;
  }

  const beginSubscription = () => {
    createPaymentSession({
      variables: {
        input: {
          successUrl: window.location.href,
          cancelUrl: window.location.href,
          campaignId: getCampaignId() || undefined,
        },
      },
    }).then(({ data }) => {
      if (!data) {
        return;
      }

      stripe.redirectToCheckout({
        sessionId: data.session.id,
      });
    });
  };

  return (
    <PaymentContainer>
      <h1>Unlock all episodes for $10</h1>
      <p>
        We're asking for a small fee for full access so we can bring you and
        your family even more fun and engaging content.
      </p>

      <div className="payment">
        <button onClick={beginSubscription}>Click here to subscribe</button>
      </div>

      <div className="back-link">
        <Link to="/">&laquo; Back to episodes</Link>
      </div>
    </PaymentContainer>
  );
};

const CREATE_PAYMENT_SESSION = gql`
  mutation CreatePaymentSession($input: CreatePaymentSessionInput!) {
    session: createPaymentSession(input: $input) {
      id
    }
  }
`;

const PaymentContainer = styled.div`
  max-width: 1024px;
  margin: 60px auto;
  padding: 60px;
  border-radius: 30px;

  h1 {
    font-size: 36px;
  }

  p {
    color: #777;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 200;
  }

  .back-link {
    margin-top: 30px;

    a {
      text-decoration: none;
      color: #666;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .payment {
    font-size: 24px;
    margin-top: 30px;

    h2 {
      margin: 0 0 30px 0;
      font-size: 24px;
    }

    button {
      font-size: 24px;
      font-weight: 200;
      background: #73b7e6;
      color: #fff;
      border-radius: 10px;
      outline: 0;
      border: none;
      padding: 15px 30px;
      cursor: pointer;
      transition: all 0.1s linear;
      position: relative;
      top: 0px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
`;

export const AuthCallback = () => {
  const { user, isLoading, isAuthenticated } = useAuth0();

  if (isLoading) {
    return null;
  }

  if (user) {
    analytics.app.events.auth.loggedIn({
      userId: user.sub,
      userEmail: user.email,
      userName: user.name,
    });
  }

  const redirectPath =
    (isAuthenticated ? localStorage.pathBeforeLogin : null) || '/';

  return <Redirect to={redirectPath} />;
};
