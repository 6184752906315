import React from 'react';
import styled from '@emotion/styled';

export default function BannerBackdrop(props: { src: string }) {
  return (
    <Container>
      <img src={props.src} alt="" />
    </Container>
  );
}

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #111;
  overflow-x: hidden;

  img {
    filter: brightness(0.3) blur(6px);
    position: relative;
    height: 100%;

    @media (min-aspect-ratio: 3/2) {
      height: auto;
      width: 100%;
    }
  }
`;
