import { Episode } from '../types';

export const computeEpisodeDuration = (
  episode: Episode,
  maxPlayers: number
) => {
  return (
    episode.scenes?.reduce((ms, { timer, videoStart, videoEnd }) => {
      if (timer) {
        return ms + timer * 1000 * maxPlayers;
      }

      if (videoEnd) {
        return ms + (videoEnd - (videoStart || 0)) * 1000;
      }

      return ms;
    }, 0) || 0
  );
};

export const computeRoundedEpisodeDurationMinutes = (
  episode: Episode,
  maxPlayers: number,
  minutesCeil: number = 1
) => {
  return (
    Math.ceil(
      computeEpisodeDuration(episode, maxPlayers) / 1000 / 60 / minutesCeil
    ) * minutesCeil
  );
};

export const getRoundedDurationRange = (
  episode: Episode,
  minutesCeil?: number
) => {
  const min = computeRoundedEpisodeDurationMinutes(
    episode,
    episode.size.min,
    minutesCeil
  );

  const max = computeRoundedEpisodeDurationMinutes(
    episode,
    episode.size.max,
    minutesCeil
  );

  if (min === max) {
    return [min];
  }

  return [min, max];
};

export const getRoundedDurationMean = (
  episode: Episode,
  minutesCeil: number = 1
) => {
  const range = getRoundedDurationRange(episode, 1);

  return (
    Math.ceil(
      range.reduce((sum, bound) => sum + bound, 0) / range.length / minutesCeil
    ) * minutesCeil
  );
};
