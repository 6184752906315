import { codeToSlug } from './rooms';

export const getRoomLobbyPath = (roomCode: string = ':roomSlug'): string => {
  return `/rooms/${codeToSlug(roomCode)}`;
};

export const getRoomJoinPath = (roomCode: string = ':roomSlug'): string => {
  return `${getRoomLobbyPath(codeToSlug(roomCode))}/join`;
};

export const getRoomPresencePath = (
  roomCode: string = ':roomSlug',
  presenceId: string = ':presenceId'
): string => {
  return `${getRoomLobbyPath(codeToSlug(roomCode))}/presences/${presenceId}`;
};

export const getRoomCreationPath = (episodeId: string = ':episodeId') => {
  return `/episodes/${episodeId}/create-room`;
};
