import { Room } from '../types';

export type RoomStateLogic = ReturnType<typeof computeRoomStateLogic>;

export const computeRoomStateLogic = (props: {
  room: Room;
  presenceId?: string;
}) => {
  const { room, presenceId } = props;
  const { presences } = room.state;
  const allPlayers = presences.flatMap((p) => p.players);
  const minSizeDiff = room.episode.size.min - allPlayers.length;
  const maxSizeDiff = room.episode.size.max - allPlayers.length;
  const canStart = minSizeDiff <= 0;
  const isPlayer = presences.some((p) => p.id === presenceId);
  const canJoin = !isPlayer && allPlayers.length < room.episode.size.max;
  const audienceMode = !isPlayer;

  return {
    minSizeDiff,
    maxSizeDiff,
    isPlayer,
    canJoin,
    canStart,
    audienceMode,
  };
};
