import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { AuthCallback, RequireLogin } from './components/Auth';
import EpisodesRoute from './routes/Episodes';
import RoomsRoute from './routes/Rooms';

const App = () => {
  return (
    <Switch>
      <Route path="/rooms/:roomSlug">
        <RoomsRoute />
      </Route>
      <Route path="/auth/callback">
        <AuthCallback />
      </Route>
      <Route path="/">
        <RequireLogin>
          <EpisodesRoute />
        </RequireLogin>
      </Route>
    </Switch>
  );
};

export default App;
