import React from 'react';
import styled from '@emotion/styled';
import marked from 'marked';
import { useRef } from 'react';
import { useEffect } from 'react';

const Content = (props: { source: string; onButtonPress?: () => void }) => {
  const { source, onButtonPress } = props;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const div = ref.current;

    if (!onButtonPress || !div) {
      return;
    }

    const button = div.querySelector('button');

    if (!button) {
      return;
    }

    const onClick = () => {
      onButtonPress();
    };

    button.addEventListener('click', onClick);

    return () => {
      button.removeEventListener('click', onClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, onButtonPress]);

  return (
    <Container
      ref={ref}
      className="content"
      dangerouslySetInnerHTML={{
        __html: marked(source),
      }}
    />
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  font-size: clamp(12px, 3vh, 32px);
  padding: 0 10px;
  max-height: auto;

  & > * {
    max-width: 800px;
    width: 100%;
    margin: 0 auto 1em 0;
    line-height: 1.25;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.15em;
  }

  h3 {
    font-size: 1.05em;
  }

  p {
    font-size: 1em;
  }

  button {
    padding: 0.6em 0.8em;
    font-size: 24px;
    border-radius: 14px;
    border: 0;
    outline: 0;
    background: #111;
    color: #fff;
    display: block;
    margin: 30px auto 0 auto;
  }

  iframe:first-of-type:last-child {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    transform: translateX(-50%) translateY(-50%);
    border-radius: 2vw;
    z-index: 99999;
    border: 0;
    margin: 0;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border: 15px solid rgba(255, 255, 255, 0.2);
  }
`;

export default Content;
