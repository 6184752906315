import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import analytics from '../../lib/analytics';
import { useAuth0 } from '@auth0/auth0-react';

export interface TimerProps {
  duration: number;
  visible?: boolean;
  onEnded: () => any;
}

export default function Timer(props: TimerProps) {
  const { secondsLeft } = useTimerState(props);
  const formattedTime = formatTime(secondsLeft);

  return props.visible !== false ? (
    <Container>{formattedTime}</Container>
  ) : null;
}

export const useTimerState = ({ duration, onEnded }: TimerProps) => {
  const [secondsLeft, setSecondsLeft] = useState(duration);

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSecondsleft = Math.max(0, secondsLeft - 1);

      setSecondsLeft(nextSecondsleft);

      if (nextSecondsleft === 0) {
        clearInterval(interval);
        onEnded();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondsLeft]);

  return { secondsLeft };
};

export const formatTime = (secondsLeft: number): string => {
  const minutes = Math.floor(secondsLeft / 60);

  const secondsFormatted = (Math.floor(secondsLeft % 60) / 100)
    .toFixed(2)
    .slice(-2);

  return minutes > 0
    ? `${minutes}:${secondsFormatted}`
    : `:${secondsFormatted}`;
};

const Container = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  background: rgba(0, 0, 0, 0.25);
  padding: 3px 12px;
  border-radius: 8px;
  font-size: clamp(24px, 2.5vw, 64px);
  font-weight: 200;
  color: #fff;
  z-index: 9999;
  height: auto;
  display: inline-block;
`;
