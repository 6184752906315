import { ApolloProvider } from '@apollo/client';
import { Auth0Provider } from '@auth0/auth0-react';
import config from './config';
import { apollo } from './apollo';

export const ProviderWrapper = (props: { children: any }) => {
  return (
    <Auth0Provider {...config.auth0}>
      <ApolloProvider client={apollo}>{props.children}</ApolloProvider>
    </Auth0Provider>
  );
};
