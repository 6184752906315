import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useEpisode, useRoomMethods } from '../../hooks';
import PrimaryDevice from './PrimaryDevice';
import SecondaryDevice from './SecondaryDevice';
import { useLiveRoom } from '../LiveRoomContext';
import { Scene } from '../../types';
import Message from '../Message';
import analytics from '../../lib/analytics';
import { RequireLogin } from '../Auth';

const EpisodeEngine = () => {
  const { room, logic, presenceId } = useLiveRoom();
  const episodeQuery = useEpisode(room.episode);
  const { sceneIndex } = room.state;
  const roomMethods = useRoomMethods(room.id);

  if (episodeQuery.loading) {
    return <Message>Loading...</Message>;
  }

  if (episodeQuery.error) {
    return <Message>{episodeQuery.error.message}</Message>;
  }

  if (!episodeQuery.data) {
    return <Message>No episode data found.</Message>;
  }

  // Get scenes and compute current one;
  const { scenes, idleBg } = episodeQuery.data;
  const scene: Scene | undefined = scenes[sceneIndex] || scenes.slice().pop();

  // After scenes are loaded
  if (scenes.length === 0) {
    return (
      <Container>
        <p>Nothing to see in this episode yet!</p>
      </Container>
    );
  }

  const directiveClasses = scene.directives.map((d) =>
    d.toLowerCase().replace(/_/g, '-')
  );

  const presence = room.state.presences.find((p) => p.id === presenceId);

  return (
    <Container className={directiveClasses.join(' ')}>
      {presence ? (
        <SecondaryDevice
          key={`secondary:scene:${scene.id}`}
          room={room}
          scene={scene}
          presence={presence}
          idleBg={idleBg}
          onAnswer={(playerIds, answer) => {
            roomMethods.submitAnswer({
              playerIds,
              answer,
              sceneId: scene.id,
            });
          }}
        />
      ) : (
        <RequireLogin>
          <PrimaryDevice
            room={room}
            roomMethods={roomMethods}
            logic={logic}
            scenes={scenes}
            scene={scene}
            hideInputs
          />
        </RequireLogin>
      )}
    </Container>
  );
};

const Container = styled.div<{
  children: React.ReactNode | React.ReactNode[];
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #06060c;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.start-prompt {
    button {
      font-size: 21px;
    }
  }

  button {
    padding: 0.5em;
    color: #111;
    font-size: 18px;
  }

  h1 {
    font-size: 48px;
    font-weight: 200;
  }

  h2 {
    font-size: 42px;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: 28px;
  }

  p {
    font-size: 24px;
  }

  &.img-as-bg {
    .media.image {
      img {
        position: fixed;
        top: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: -1;
      }
    }
  }

  .media {
    video {
      position: relative;
      max-height: 100%;
      max-width: 100%;
    }

    img {
      position: relative;
      max-height: 100%;
      max-width: 100%;
    }
  }
`;

export default EpisodeEngine;
