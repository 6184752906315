import styled from '@emotion/styled';

export default styled.button`
  color: #111;
  border-radius: 0.25em;
  border: 0;
  opacity: 1;
  background: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  padding: 0.75em;
  cursor: pointer;
  outline: 0;
`;
