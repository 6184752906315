import styled from '@emotion/styled';
import React from 'react';
import { Room } from '../../types';
import Avatar from '../Avatar';

const Participants = (props: {
  room: Room;
  quote?: {
    playerId: string;
    text: string;
  };
}) => {
  const { room, quote } = props;
  const { state } = room;

  return (
    <Container>
      {state.presences.flatMap((presence) => {
        return presence.players.map((player) => {
          const isQuoted = player.id === quote?.playerId;
          const classes = [presence.isConnected ? 'connected' : 'disconnected'];

          if (isQuoted) {
            classes.push('quoted');
          }

          return (
            <li key={player.id} className={classes.join(' ')}>
              <Avatar name={player.name} src={player.avatarUrl} />
              {isQuoted && <blockquote>{quote?.text}</blockquote>}
            </li>
          );
        });
      })}
    </Container>
  );
};

const Container = styled.ul`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
  list-style: none;
  display: flex;
  justify-content: left;
  z-index: 2;

  li {
    position: relative;
    top: 0px;
    text-align: center;
    transition: all 0.3s ease-out;
    margin-right: 5px;

    .avatar {
      margin: 0 auto !important;

      .face-container {
        display: block;
        height: 60px !important;
        width: 60px !important;
        margin: 0 auto;

        img {
          width: 100%;
          margin: 0 auto 3px auto;
          transition: all 0.3s ease-out;
        }
      }

      .name {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease-out;
        margin-top: 5px;
      }
    }

    &.quoted {
      margin-top: -110px;
      height: 100px !important;

      blockquote {
        opacity: 1;
        transform: rotate(0deg);
      }

      .name {
        font-size: 15px;
      }
    }

    blockquote {
      position: absolute;
      left: 100%;
      top: 12px;
      margin-left: 10px;
      font-size: 18px;
      opacity: 0;
      transition: all 0.3s ease-out;
      transform: rotate(10deg);
      background: #fff;
      padding: 0.5em 1em;
      max-width: 50vw;
      color: #222;
      border-radius: 30px;
    }

    &.disconnected {
      opacity: 0.5;
    }
  }
`;

export default Participants;
