import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Room } from '../types';
import SecondDeviceQR from './RoomQR';
import { RoomStateLogic } from '../lib/room-logic';
import Button from './Button';
import { Link, Redirect } from 'react-router-dom';
import LobbyParticipantsList from './LobbyParticipantsList';
import BannerBackdrop from './BannerBackdrop';
import { useLiveRoom } from './LiveRoomContext';
import { useRoomMethods } from '../hooks';
import { getRoomJoinPath } from '../lib/paths';
import { getRoundedDurationMean } from '../lib/episodes';
import analytics from '../lib/analytics';
import { useAuth0 } from '@auth0/auth0-react';

export interface LobbyProps {
  room: Room;
  startEpisode: () => void;
  logic: RoomStateLogic;
}

export const LobbyWrapper = (props: PropsWithChildren<{ room: Room }>) => {
  const { bannerUrl } = props.room.episode.display.web;

  return (
    <Container>
      {bannerUrl && <BannerBackdrop src={bannerUrl} />}
      <div className="inner">{props.children}</div>
    </Container>
  );
};

const Lobby = () => {
  const { user } = useAuth0();
  const { room, logic } = useLiveRoom();
  const { activateRoom } = useRoomMethods(room.id);

  if (!room || !logic) {
    return null;
  }

  if (logic.isPlayer) {
    return <Redirect to={getRoomJoinPath(room.code)} />;
  }

  const { canStart, minSizeDiff, maxSizeDiff } = logic;

  let sizeStatus = '';

  if (minSizeDiff > 0) {
    sizeStatus = `We need ${minSizeDiff} more to join!`;
  } else if (maxSizeDiff > 0 && minSizeDiff <= 0) {
    sizeStatus = `We have enough people to start!`;
  } else if (minSizeDiff <= 0) {
    sizeStatus = `The room is full, let's start!`;
  }

  return (
    <LobbyWrapper room={room}>
      <div className="info">
        <Link
          className="back"
          to="/"
          onClick={() => {
            analytics.app.events.room.backToAllEpisodes({
              userId: user?.sub,
              userEmail: user?.email,
              userName: user?.name,
              roomId: room.id,
              episodeId: room.episode.id,
            });
          }}
        >
          « All Episodes
        </Link>
        <h2>{room.episode.name}</h2>
        <p className="estimated-time">
          {getRoundedDurationMean(room.episode, 5)} minutes
        </p>
        <p className="blurb">{room.episode.blurb}</p>
      </div>
      <Columns>
        <div className="join">
          <h3>Your smartphone or tablet will act as a game controller.</h3>
          <Columns>
            <div>
              <SecondDeviceQR roomCode={room.code} />
            </div>
            <div className="instructions">
              <p>Open your camera and scan the QR Code to the left.</p>
              <p>
                Don’t worry, if some players don’t have a mobile device you can
                add multiple players to a single controller in the next step.
              </p>
            </div>
          </Columns>
        </div>
        <div>
          {room.state.presences.length > 0 && (
            <>
              <h3>Participants</h3>

              {sizeStatus.length > 0 && (
                <div className="size-status">
                  <p>{sizeStatus}</p>
                </div>
              )}

              <LobbyParticipantsList room={room} />
            </>
          )}
        </div>
      </Columns>

      {canStart && (
        <Button className="launch-button" onClick={activateRoom}>
          Start
        </Button>
      )}
    </LobbyWrapper>
  );
};

const Container = styled.div`
  color: #fff;
  background: #111;
  padding: 30px;
  position: relative;
  z-index: 9999;

  .inner {
    max-width: 1280px;
    margin: 0 auto;
  }

  .inner > * {
    position: relative;
  }

  .info {
    max-width: 1280px;
    margin-bottom: 60px;
  }

  .back {
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
  }

  h2 {
    font-size: clamp(32px, 10vw, 80px);
    font-weight: 100;
    margin: 0.25em 0;
  }

  h3 {
    font-size: 32px;
    margin: 1em 0 1em 0;
  }

  p {
    margin-bottom: 1em;

    strong {
      font-weight: bold;
    }
  }

  .blurb {
    font-size: 32px;
    width: 100%;
    max-width: 1280px;
    line-height: 1.5;
    margin-bottom: 0;
    font-weight: 200;
  }

  .join {
    p {
      margin-top: 1.5em;
      font-size: 21px;
    }

    code {
      display: inline-block;
      padding: 1em;
      background: rgba(0, 0, 0, 0.5);
      font-size: 24px;
      font-weight: 500;
      border-radius: 12px;
    }
  }

  .launch-button {
    position: fixed;
    z-index: 9999;
    right: 30px;
    bottom: 30px;
    font-size: 24px;
    background: #39f;
    color: #fff;
  }

  .instructions {
    max-width: 500px;
    p {
      margin: 0 0 1em 0;
      line-height: 1.2;
    }
  }
`;

const Columns = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
  padding-bottom: 100px;
  margin: 0 auto;
  position: relative;
  z-index: 9999;

  & > * {
    padding-right: 30px;
  }
`;

export default Lobby;
