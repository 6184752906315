import { gql, useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import analytics from '../lib/analytics';
import { Episode } from '../types';
import { RequireLogin, RequireSubscription } from './Auth';

const CreateRoom = () => {
  const { episodeId } = useParams<{ episodeId: string }>();

  const { loading, error, data } = useQuery<
    { episode: Episode },
    { id: string }
  >(GET_EPISODE, {
    variables: {
      id: episodeId,
    },
  });

  const view = <CreateAndRedirect episodeId={episodeId} />;

  if (loading) {
    return null;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (data?.episode.free) {
    return <RequireLogin>{view}</RequireLogin>;
  }

  return <RequireSubscription>{view}</RequireSubscription>;
};

const GET_EPISODE = gql`
  query GetEpisode($id: ID!) {
    episode(id: $id) {
      free
    }
  }
`;

const CreateAndRedirect = (props: { episodeId: string }) => {
  const { user } = useAuth0();
  const [roomCreated, setRoomCreated] = useState(false);

  const [createRoom, { loading, error, data }] = useMutation<
    {
      room: {
        id: string;
        code: string;
      };
    },
    typeof props
  >(CREATE_ROOM_MUTATION);

  useEffect(() => {
    if (!roomCreated) {
      createRoom({ variables: props }).then(({ data }) => {
        analytics.app.events.room.created({
          roomId: data?.room.id,
          episodeId: props.episodeId,
          userId: user?.sub,
          userEmail: user?.email,
          userName: user?.name,
        });

        setRoomCreated(true);
      });
    }
  }, [roomCreated, createRoom, props, user?.sub, user?.email, user?.name]);

  if (loading) {
    return null;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!data?.room) {
    // This shouldn't happen without an error being generated. Regardless:
    return <p>Error: create room operation failed.</p>;
  }

  return <Redirect to={`/rooms/${data.room.code.replace(/ /g, '-')}`} />;
};

const CREATE_ROOM_MUTATION = gql`
  mutation CreateRoom($episodeId: ID!) {
    room: createRoom(input: { episodeId: $episodeId }) {
      id
      code
    }
  }
`;

export default CreateRoom;
