import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import AccountNav from '../components/AccountNav';

export default function SiteLayout(props: PropsWithChildren<{}>) {
  return (
    <>
      <Header>
        <h1>
          <Link to="/">
            <img
              src="https://rogersworld.tv/wp-content/uploads/2021/07/cropped-cropped-RW-Episode-2.png"
              alt="Roger's World"
            />
          </Link>
        </h1>
        {/* <CodeLauncher /> */}
        <AccountNav />
      </Header>
      <Main>{props.children}</Main>
    </>
  );
}

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px 8px 15px;
  background: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  h1 {
    height: 70px;

    img {
      height: 100%;
    }
  }
`;

const Main = styled.main`
  padding: 30px;
`;
