import React, { useEffect } from 'react';

export default function Vibration(props: { pattern: number[] }) {
  const { vibrate } = window.navigator;

  useEffect(() => {
    vibrate?.call(window.navigator, props.pattern);
  }, [vibrate, props.pattern]);

  return <></>;
}
