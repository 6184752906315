import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';

export interface VideoPlayerProps {
  src: string;
  start?: number;
  end?: number;
  loop?: boolean;
  paused?: boolean;
  onStarted?: () => any;
  onEnded?: () => void;
}

const VideoPlayer2 = (props: VideoPlayerProps) => {
  const {
    src,
    loop,
    start = 0,
    end = Infinity,
    onStarted = () => {},
    onEnded = () => {},
  } = props;

  const [light, setLight] = useState(false);
  const [visible, setVisible] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [paused, setPaused] = useState(props.paused);
  const ref = useRef<ReactPlayer>(null);

  const getCurrentTime = () => {
    return ref.current?.getCurrentTime();
  };

  const seek = (s: number) => {
    const video = ref.current as ReactPlayer;
    const initialTime = video.getCurrentTime();

    video.seekTo(s);

    return new Promise<void>((resolve) => {
      const check = () => {
        const currentTime = video.getCurrentTime();

        if (currentTime !== initialTime) {
          resolve();
        } else {
          setTimeout(check, 100);
        }
      };

      check();
    });
  };

  const checkTime = () => {
    const currentTime = getCurrentTime();

    if (currentTime === undefined) {
      return;
    }

    if (currentTime < start || currentTime > end) {
      seek(start).then(() => {
        setTimeout(() => setVisible(true), 300);
      });
    } else {
      setVisible(true);
    }
  };

  const onReady = (player: ReactPlayer) => {
    checkTime();
    onStarted();
  };

  const onPlay = () => {
    setPlaying(true);
  };

  const onProgress = () => {
    const currentTime = getCurrentTime();

    if (currentTime && currentTime > end) {
      if (loop) {
        seek(0);
      } else {
        onEnded();
      }
    }
  };

  const onError = () => {
    setLight(true);
  };

  const togglePause = () => {
    if (playing) {
      setPaused(!paused);
    }
  };

  useEffect(checkTime, [start, end]);

  return (
    <VideoWrapper
      className={visible ? 'video-wrapper playing' : 'video-wrapper'}
      onClick={togglePause}
    >
      <ReactPlayer
        playsinline
        playing={!paused}
        light={light}
        playIcon={<PlayIcon />}
        width="auto"
        height="auto"
        ref={ref}
        url={src}
        controls={false}
        onReady={onReady}
        onProgress={onProgress}
        onPlay={onPlay}
        onError={onError}
      />
    </VideoWrapper>
  );
};

const PlayIcon = styled.div`
  font-size: 32px;

  &::before {
    content: 'Is everyone ready?';
    display: block;
    font: inherit;
  }
  &::after {
    content: '▶ Play';
    display: block;
    margin-top: 1em;
    border: 2px solid #fff;
    border-radius: 1em;
    padding: 0.5em;
  }
`;

const VideoWrapper = styled.div`
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: all 0.4s linear;
  margin: 0 20px;

  &.playing {
    opacity: 1;
  }
`;

export default VideoPlayer2;
