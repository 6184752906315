import React, { useState } from 'react';
import styled from '@emotion/styled';

export const AVATAR_CLASS_NAME = 'avatar';

export interface AvatarProps {
  name?: string;
  src: string;
  animation?: AnimationType;
}

type AnimationType = 'pop';

const Avatar = ({ name, src, animation }: AvatarProps) => {
  const [state, setState] = useState({
    loaded: false,
    error: false,
  });

  const handleLoad = () => {
    setState({
      ...state,
      loaded: true,
    });
  };

  const handleError = () => {
    setState({
      ...state,
      error: true,
    });
  };

  const className = [
    AVATAR_CLASS_NAME,
    state.loaded ? 'loaded' : 'loading',
  ].join(' ');

  return (
    <Container className={className} animation={animation}>
      <div className="face-container">
        {state.error ? (
          <span className="initials">
            {name
              ? name
                  .split(' ')
                  .map((part) => part[0])
                  .join('')
              : '?'}
          </span>
        ) : (
          <img
            src={src}
            alt={name || ''}
            onLoad={handleLoad}
            onError={handleError}
          />
        )}
      </div>
      <span className="name">{name || 'Anonymous'}</span>
    </Container>
  );
};

const Container = styled.span<{
  animation?: AnimationType;
}>`
  display: inline-block;
  width: clamp(50px, 10vh, 100px);
  position: relative;

  .face-container {
    position: relative;
    width: clamp(50px, 10vh, 100px);
    height: clamp(50px, 10vh, 100px);
    max-width: 100%;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    overflow: hidden;
  }

  img {
    width: 100%;
    max-width: 100px;
    margin-bottom: 5px;
  }

  .initials {
    border-radius: 50%;
    height: 100px;
    width: 100px;
    display: block;
    background: rgba(0, 0, 0, 0.25);
    font-size: 25px;
    font-weight: 200;
    text-align: center;
    line-height: 1;
    padding: calc(50% - 0.5em) 0;
  }

  .name {
    display: block;
    text-align: center;
    font-size: 16px;
  }

  ${(props) =>
    props.animation === 'pop' &&
    `

    @keyframes avatarPop {
      from {
        transform: scale(.1);
      }

      20% {
        transform: scale(1.2);
      }

      40% {
        transform: scale(.8);
      }

      50% {
        transform: scale(1.1);
      }

      70% {
        transform: scale(.9);
      }

      to {
        transform: scale(1);
      }
    }
    
    &.loaded {
      animation-name: avatarPop;
      animation-duration: .5s;
    }
  `}
`;

export default Avatar;
