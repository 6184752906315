import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { Scene } from '../../types';

const SceneNavigator = (props: {
  isLeader: boolean;
  scenes: Scene[];
  currentScene: Scene;
  onSelectScene?: (scene: Scene) => void;
}) => {
  const [show, setShow] = useState(false);
  const { isLeader, scenes, currentScene, onSelectScene = () => {} } = props;

  const toggle = useCallback(() => {
    setShow(!show);
  }, [show]);

  useEffect(() => {
    const handleHotKey = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'm') {
        toggle();
      }
    };

    const handleDocClick = () => {
      if (!show) {
        return;
      }

      setShow(false);
    };

    document.addEventListener('click', handleDocClick);
    document.addEventListener('keydown', handleHotKey);

    return () => {
      document.removeEventListener('click', handleDocClick);
      document.removeEventListener('keydown', handleHotKey);
    };
  }, [toggle, show, currentScene.id]);

  const currentSelectableScene = getCurrentSelectableScene(
    scenes,
    currentScene
  );

  return (
    <SceneNavigatorContainer>
      {show && (
        <ul>
          {scenes
            .filter((scene) => !scene.isSubscene)
            .map((scene) => {
              const handleClick = () => {
                if (isLeader) {
                  onSelectScene(scene);
                }
              };

              const classes = ['scene'];

              if (currentSelectableScene.id === scene.id) {
                classes.push('current');
              } else if (isLeader) {
                classes.push('selectable');
              }

              return (
                <li
                  key={scene.id}
                  className={classes.join(' ')}
                  onClick={handleClick}
                >
                  {scene.name}
                </li>
              );
            })}
        </ul>
      )}
    </SceneNavigatorContainer>
  );
};

const getCurrentSelectableScene = (scenes: Scene[], currentScene: Scene) => {
  // Current scene is selectable, return it.
  if (!currentScene.isSubscene) {
    return currentScene;
  }

  let outScene: Scene = currentScene;

  for (const scene of scenes) {
    // We've iterated to the current scene--return whatever suitable
    // scene we can that came before it.
    if (scene.id === currentScene.id) {
      return outScene;
    }

    // If not a subscene, this might be the last non-subscene before
    // we get to the current scene.
    if (!scene.isSubscene) {
      outScene = scene;
    }
  }

  // This would only really happen if there wasn't a leading non-subscene.
  return outScene;
};

const SceneNavigatorContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 9999;
  text-align: right;

  ul {
    list-style: none;
    font-size: 13px;
    position: relative;
    top: -5px;

    li {
      padding: 10px;
      text-align: left;
      background: rgba(0, 0, 0, 0.4);

      &.current {
        background: #fff;
        color: #111;
      }

      &.selectable {
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.9);
          color: #fff;
        }
      }
    }
  }

  .toggle {
    font-size: 14px;
    border: 0;
    outline: 0;
    background: rgba(12, 12, 12, 0.5);
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      background: rgba(12, 12, 12, 1);
    }
  }
`;

export default SceneNavigator;
