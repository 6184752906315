import React from 'react';
import { Link, Route, Switch, useParams } from 'react-router-dom';
import {
  getRoomLobbyPath,
  getRoomJoinPath,
  getRoomPresencePath,
} from '../lib/paths';
import JoinRoom from '../components/JoinRoom';
import LiveRoomRenderer from '../components/LiveRoomRenderer';
import { slugToCode } from '../lib/rooms';
import { gql, useQuery } from '@apollo/client';
import { Room } from '../types';
import { LiveRoomProvider } from '../components/LiveRoomContext';
import Message from '../components/Message';

const RoomsRoute = () => {
  const { room, message } = useRoomInfo();

  if (message) {
    return <Message>{message}</Message>;
  }

  if (!room) {
    return <p>Error loading room.</p>;
  }

  return (
    <LiveRoomProvider room={room}>
      <Switch>
        <Route exact path={getRoomPresencePath()}>
          <LiveRoomRenderer />
        </Route>
        <Route exact path={getRoomJoinPath()}>
          <JoinRoom room={room} />
        </Route>
        <Route exact path={getRoomLobbyPath()}>
          <LiveRoomRenderer />
        </Route>
      </Switch>
    </LiveRoomProvider>
  );
};

const useRoomParams = () => {
  const { roomSlug, presenceId } = useParams<{
    roomSlug: string;
    presenceId?: string;
  }>();

  return {
    presenceId,
    roomSlug,
    roomCode: roomSlug && slugToCode(roomSlug),
  };
};

const useRoomInfo = () => {
  const { roomCode, presenceId } = useRoomParams();

  const { loading, error, data } = useQuery<
    { room: Room | null },
    { roomCode: string }
  >(ROOM_CONTEXT_QUERY, {
    variables: {
      roomCode,
    },
  });

  let message: undefined | React.ReactNode;

  if (loading) {
    message = 'Loading room...';
  } else if (error) {
    message = error.message;
  } else if (data && !data.room) {
    message = (
      <>
        Could not find room with that code! <Link to="/">Go to Homepage</Link>
      </>
    );
  }

  return {
    loading,
    error,
    message,
    presenceId,
    room: data?.room,
  };
};

const ROOM_CONTEXT_QUERY = gql`
  query GetRoomContextByCode($roomCode: String!) {
    room(code: $roomCode) {
      id
      code
      state {
        phase
        sceneIndex
        presences {
          id
          isConnected
          isReadyForNextScene
          players {
            id
            name
            avatarUrl
          }
        }
      }
      episode {
        id
        name
        blurb
        size {
          min
          max
        }
        scenes {
          id
          timer
          videoStart
          videoEnd
        }
        display {
          web {
            bannerUrl
          }
        }
      }
    }
  }
`;

export default RoomsRoute;
