import React from 'react';
import { Global, css } from '@emotion/react';

const GLOBAL_CSS = css`
  @import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap');

  root {
    --primary-brand: #73b7e6;
    --primary-dark: #4a4a4a;
  }

  * {
    margin: 0;
    padding: 0;
    font: inherit;
    color: inherit;
    box-sizing: border-box;
  }

  html {
    min-height: 100vh;
    position: relative;
  }

  body {
    font-family: 'Rubik', Helvetica Neue, Helvetica, Arial, sans-serif;
    color: var(--primary-dark);
    background: #f3f3f3;
    -webkit-font-smoothing: antialiased;
    min-height: 100vh;
    position: relative;
  }

  button {
    padding: 0.5em;
  }

  h2,
  h3,
  h4 {
    margin: 1em 0 0.5em;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18px;
  }

  p {
    margin: 1em 0;
  }
`;

const GlobalStyles = () => <Global styles={GLOBAL_CSS} />;

export default GlobalStyles;
