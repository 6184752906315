import { useEffect } from 'react';

export default function Sound(props: { url: string }) {
  const { url } = props;

  useEffect(() => {
    const sound = new Audio(url);
    sound.play();
  }, [url]);

  return null;
}
