import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Redirect } from 'react-router-dom';
import { useRoomMethods } from '../hooks';
import { Room } from '../types';
import { getRoomLobbyPath, getRoomPresencePath } from '../lib/paths';
import Avatar from './Avatar';
import { useLiveRoom } from './LiveRoomContext';
import analytics from '../lib/analytics';

export interface JoinRoomProps {
  room: Room;
}

export interface JoinRoomState {
  presenceId?: string;
}

export default function JoinRoom(props: JoinRoomProps) {
  const { room, presenceId, logic, setPresenceId } = useLiveRoom();
  const { joinRoom } = useRoomMethods(props.room.id);
  const { maxSizeDiff } = logic;

  if (room.state.phase === 'ACTIVE') {
    if (presenceId) {
      // TODO: This is a hack to get around real time state update issues. Fix it.
      window.location.href = getRoomPresencePath(room.code, presenceId);
    }

    return <Redirect to={getRoomLobbyPath(room.code)} />;
  }

  const ourPresence = room.state.presences.find((p) => p.id === presenceId);

  const peopleOnThisDevice = ourPresence && ourPresence.players.length > 0 && (
    <>
      <h4>On this device:</h4>
      <AvatarSet>
        {ourPresence.players.map((player) => {
          return (
            <li key={player.id}>
              <Avatar name={player.name} src={player.avatarUrl} />
            </li>
          );
        })}
      </AvatarSet>
    </>
  );

  if (maxSizeDiff === 0) {
    return (
      <Container>
        <main>
          <h3>The room is full!</h3>
          <p>Waiting for the episode to start...</p>
          {peopleOnThisDevice}
        </main>
      </Container>
    );
  }

  const handleSubmitPerson = async ({ name, age }: AddFormFields) => {
    const { data } = await joinRoom({
      presenceId,
      playerNames: [name],
    });

    const newPresenceId = data?.presence.id;

    if (newPresenceId && newPresenceId !== presenceId) {
      setPresenceId(newPresenceId);
    }

    analytics.app.events.room.joined({
      roomId: room.id,
      episodeId: room.episode.id,
      deviceId: newPresenceId,
      playerId: data?.presence.players.slice(-1)[0]?.id,
      playerAge: age,
    });
  };

  return (
    <Container>
      <main>
        <h2>{props.room.episode.name}</h2>
        <AddForm onSubmit={handleSubmitPerson} />
        {peopleOnThisDevice}
      </main>
    </Container>
  );
}

const ADD_FORM_DEFAULTS = {
  name: '',
  age: 10,
};

type AddFormFields = typeof ADD_FORM_DEFAULTS;

const AddForm = (props: { onSubmit: (values: AddFormFields) => void }) => {
  const [state, setState] = useState(ADD_FORM_DEFAULTS);

  const handleChangeName = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setState({
      ...state,
      name: value,
    });
  };

  const handleChangeAge = (event: React.FormEvent<HTMLInputElement>) => {
    const { valueAsNumber } = event.currentTarget;

    setState({
      ...state,
      age: valueAsNumber,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.onSubmit(state);
    setState({
      ...state,
      ...ADD_FORM_DEFAULTS,
    });
  };

  return (
    <AddFormContainer onSubmit={handleSubmit}>
      <h3>Join the room!</h3>
      <label>
        Name:
        <input
          value={state.name}
          onChange={handleChangeName}
          type="text"
          autoFocus
          required
        />
      </label>
      <label>
        Age:
        <input
          value={state.age}
          onChange={handleChangeAge}
          min={1}
          max={130}
          type="number"
          required
        />
      </label>
      <input type="submit" value="Add" />
    </AddFormContainer>
  );
};

const Container = styled.div`
  background: #72b4df
    url(https://dl.airtable.com/.attachments/150cf20f4616305d8cd2a5c3b4439603/4fe4542a/CreateNewRoomGIF.gif)
    no-repeat;
  background-position: bottom right;
  position: relative;
  min-height: 100vh;

  * {
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  }

  input {
    text-shadow: none;
  }

  main {
    position: relative;
    z-index: 2;
    color: #fff;
    padding: 30px;
  }

  h2 {
    font-size: 24px !important;
    font-weight: 500;
    margin-top: 0;
  }

  h3 {
    font-size: 21px;
    font-weight: normal;
    margin-bottom: 0.25em;
  }
`;

const AddFormContainer = styled.form`
  label {
    margin: 15px 0;
    display: block;

    input {
      display: block;
      width: 100vw;
      margin-top: 10px;
    }
  }

  input {
    font-size: 19px;
    margin-right: 15px;
    border: 0;
    outline: none;
  }

  input[type='text'] {
    width: 240px;
    color: #111;
    padding: 0.4em;
    border-radius: 4px;
  }

  input[type='number'] {
    width: 100px;
    color: #111;
    padding: 0.3em 0.4em;
    border-radius: 4px;
  }

  input[type='submit'] {
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    border: none;
    outline: none;
    border-radius: 8px;
    margin: 0.5em 0 0 0;
    padding: 0.4em 0.6em;
    font-size: 21px;
    border: 2px solid #fff;
    cursor: pointer;
    overflow: hidden;
  }

  p {
    font-size: 18px;
    opacity: 0.75;
  }
`;

const AvatarSet = styled.ul`
  list-style: none;

  li {
    margin-right: 10px;
    display: inline-block;
  }
`;
