import styled from '@emotion/styled';
import marked from 'marked';
import React, { useState } from 'react';
import { ScenePrompt } from '../../types';
import Submit from './Submit';

const Prompt = ({
  text,
  onAnswer = () => {},
}: ScenePrompt & {
  onAnswer?: (value: string) => any;
}) => {
  const [value, setValue] = useState('');

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    setValue(newValue);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onAnswer(value);
    setValue('');
  };

  return (
    <Container onSubmit={handleSubmit}>
      <p dangerouslySetInnerHTML={{
        __html: marked(text)
      }} />
      <input
        type="text"
        onChange={handleChange}
        value={value}
        placeholder="Write your answer here"
        autoFocus
        required
      />
      <Submit type="submit" value="Submit" />
    </Container>
  );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;

  p {
    strong {
      font-weight: bold;
    }
  }

  input[type='text'] {
    position: relative;
    z-index: 9999;
    padding: .75em 1em;
    display: block;
    width: 100%;
    max-width: 400px;
    background: #fff;
    color: #111;
    border-radius: 25px;
    border: 0;
    font-size: 18px;
    outline: 0;
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.25);
  }
`;

export default Prompt;
