import styled from '@emotion/styled';
import React, { useState } from 'react';
import { QuizChoice } from '../../types';
import Button from '../Button';

const Quiz = (props: {
  choices: QuizChoice[];
  allowMulti?: boolean;
  onAnswer?: (choices: QuizChoice[]) => any;
}) => {
  const { choices, allowMulti = false, onAnswer = () => {} } = props;
  const [chosen, setChosen] = useState(new Set<string>());

  const toggle = ({ label }: QuizChoice) => {
    const newChosen = new Set(chosen);

    if (chosen.has(label) && allowMulti) {
      newChosen.delete(label);
    } else {
      if (!allowMulti) {
        newChosen.clear();
      }

      newChosen.add(label);
    }

    setChosen(newChosen);
  };

  const handleSubmit = () => {
    onAnswer(choices.filter((c) => chosen.has(c.label)));
  };

  const handleSingleChoice = (choice: QuizChoice) => {
    toggle(choice);
    onAnswer([choice]);
  };

  return (
    <Container>
      <ul
        className={
          'choices ' +
          (allowMulti ? 'multi ' : 'single ') +
          (choices.some((c) => c.imageUrl) ? 'images' : 'text')
        }
      >
        {choices.map((choice) => {
          const isChosen = chosen.has(choice.label);

          if (allowMulti) {
            return (
              <li key={choice.label}>
                <label
                  className={isChosen ? 'choice chosen' : 'choice'}
                  onClick={() => toggle(choice)}
                >
                  <input type="checkbox" checked={isChosen} />

                  <Choice {...choice} />
                </label>
              </li>
            );
          }

          return (
            <li key={choice.label}>
              <Button onClick={() => handleSingleChoice(choice)}>
                <Choice {...choice} />
              </Button>
            </li>
          );
        })}
      </ul>

      {/* Single-choice quizes use buttons and don't need a submit button. */}
      {allowMulti && <Button onClick={handleSubmit}>Submit</Button>}
    </Container>
  );
};

const Choice = ({ label, imageUrl }: QuizChoice) => {
  if (imageUrl) {
    return <img src={imageUrl} alt={label} />;
  }

  return <>{label}</>;
};

const Container = styled.div`
  margin-top: 10px;
  font-size: clamp(12px, 3vh, 28px);

  ul {
    list-style: none;

    &.images {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      li {
        width: calc(50% - 10px);
        padding: 0;
        margin: 0;
      }

      button {
        background: none;
        width: 100%;
        padding: 0;
      }
    }
  }

  li {
    padding: 0.25em 10px;
    text-align: left;
  }

  label {
    font-size: 21px;
    display: block;
  }

  input[type='radio'] {
    padding: 15px;
    margin-right: 10px;
  }

  input[type='submit'] {
    margin: 15px auto;
  }

  button {
    background: #eee;
    color: #222;
    display: block;
    width: calc(100vw - 30px);
    text-align: center;
    font-size: 1em;
    background: #06c;
    color: #fff;
    margin: 0 auto;
    padding: 2vh;

    &.chosen {
      background: #222;
      color: #fff;
    }

    img {
      width: 100%;
    }
  }
`;

export default Quiz;
