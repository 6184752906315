import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLoginAndReturn } from '../hooks';

export default function AccountNav() {
  const { user, isLoading, isAuthenticated, logout } = useAuth0();
  const loginAndReturn = useLoginAndReturn();

  const handleClickLogin = (event: React.MouseEvent<HTMLAnchorElement>) => {
    loginAndReturn();
    event.preventDefault();
  };

  const handleClickLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    logout({ returnTo: window.location.origin });
    event.preventDefault();
  };

  return (
    <Nav>
      {user && <p>{user.email}</p>}
      <ul>
        {!isLoading && (
          <li>
            {isAuthenticated ? (
              <NavLink to="/logout" onClick={handleClickLogout}>
                Logout
              </NavLink>
            ) : (
              <NavLink to="/login" onClick={handleClickLogin}>
                Login
              </NavLink>
            )}
          </li>
        )}
      </ul>
    </Nav>
  );
}

const Nav = styled.nav`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #666;

  ul {
    list-style: none;
    color: #111;

    li {
      display: inline-block;
      margin-left: 15px;

      a {
        text-decoration: none;
        color: var(--primary-brand);

        &.active {
          color: var(--primary-dark);
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;
