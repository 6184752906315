import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

// Types
import { Media, Room, Scene } from '../../types';

// Components
import Content from './Content';
import Timer from './Timer';
import VideoPlayer from './VideoPlayer';
import DeviceContainer from './DeviceContainer';
import SceneNavigator from './SceneNavigator';
import Participants from './Participants';
import Answers from './Answers';
import PollResults from './PollResults';
import Button from '../Button';
import PopupMessage from './PopupMessage';

// Helpers
import { RoomStateLogic } from '../../lib/room-logic';
import { useRoomMethods } from '../../hooks';
import analytics from '../../lib/analytics';
import { useAuth0 } from '@auth0/auth0-react';

export interface PrimaryDeviceProps {
  room: Room;
  roomMethods: ReturnType<typeof useRoomMethods>;
  scene: Scene;
  scenes: Scene[];
  logic: RoomStateLogic;
  hideInputs?: boolean;
}

const PrimaryDevice = (props: PrimaryDeviceProps) => {
  const {
    room,
    scene,
    prevScene,
    scenes,
    allPlayers,
    hasImagery,
    handleExit,
    hasInteractions,
    handleVideoEnded,
    handleSkipToScene,
    handleCompleteScene,
    handleVideoStarted,
  } = usePrimaryDeviceState(props);

  if (scene.showAnswers) {
    if (prevScene?.directives.includes('POLL')) {
      return (
        <>
          {scene.background && <Background media={scene.background} />}
          <Timer
            key={`timer-${scene.id}`}
            duration={5}
            onEnded={handleCompleteScene}
            visible={false}
          />
          <PollResults room={room} scene={scenes[room.state.sceneIndex - 1]} />
        </>
      );
    }

    return (
      <>
        {scene.background && <Background media={scene.background} />}
        <Timer
          key={`timer-${scene.id}`}
          duration={5}
          onEnded={handleCompleteScene}
          visible={false}
        />
        <Answers room={room} scene={scenes[room.state.sceneIndex - 1]} />
      </>
    );
  }

  // Render each node based on scene attributes
  const nodes: React.ReactNode[] = [];

  if (scene.content && !hasImagery) {
    nodes.push(<Content key={`content-${scene.id}`} source={scene.content} />);
  }

  if (scene.timer) {
    // analytics.app.events.scene.timer.started(contextBase);
    nodes.push(
      <Timer
        key={`timer-${scene.id}`}
        duration={
          hasInteractions
            ? scene.directives.includes('ANSWER_AS_GROUP')
              ? scene.timer
              : allPlayers.length * scene.timer
            : allPlayers.length * scene.timer
        }
        onEnded={() => {
          // analytics.app.events.scene.timer.ended(contextBase);
          handleCompleteScene();
        }}
      />
    );
  } else if (scene.prompt || scene.quizChoices) {
    nodes.push(
      <Timer
        key={`timer-${scene.id}`}
        duration={(scene.videoEnd as number) - (scene.videoStart as number)}
        onEnded={() => {}}
      />
    );
  }

  scene.media.forEach((media: Media) => {
    switch (media.type) {
      case 'image':
        nodes.push(
          <div key={media.url} className="media image">
            <img src={media.url} alt="" />
          </div>
        );
        break;
      case 'video':
        nodes.push(
          <div key={`video-${media.id}`} className="media video">
            <VideoPlayer
              src={media.url}
              start={scene.videoStart}
              end={scene.videoEnd}
              loop={scene.directives.includes('LOOP_VIDEO')}
              onStarted={handleVideoStarted}
              onEnded={handleVideoEnded}
            />
          </div>
        );
        break;
      case 'audio':
        break;
      default:
        break;
    }
  });

  return (
    <>
      <DeviceContainer>{nodes}</DeviceContainer>

      {scene.background && (
        <Background
          media={scene.background}
          opaque={
            !(
              scene.media.length > 0 ||
              scene.content ||
              scene.quizChoices ||
              scene.prompt
            )
          }
        />
      )}

      <Participants room={room} />

      <SceneNavigator
        key={`scene-navigator:scene-${room.state.sceneIndex}`}
        scenes={scenes}
        currentScene={scene}
        isLeader={true}
        onSelectScene={handleSkipToScene}
      />

      <ExitButton onClick={handleExit}>Exit to Lobby</ExitButton>

      {(hasInteractions || (scene.media.length > 0 && scene.content)) && (
        <PopupMessage key={`popup-${scene.id}`}>
          Check your device for updates!
        </PopupMessage>
      )}
    </>
  );
};

export const usePrimaryDeviceAnalytics = (props: PrimaryDeviceProps) => {
  const { user } = useAuth0();
  const { room, scene } = props;

  const baseContext = {
    roomId: room.id,
    sceneId: scene.id,
    episodeId: room.episode.id,
    userId: user?.sub,
    userEmail: user?.email,
    userName: user?.name,
  };

  // Events for players connecting/disconnecting
  let presenceIdsMemo: string[] | null = null;

  presenceIdsMemo = useMemo(() => {
    const currentIds = room.state.presences
      .filter((p) => p.isConnected)
      .map((p) => p.id);

    if (presenceIdsMemo) {
      // Connections
      currentIds
        .filter((id) => !presenceIdsMemo?.includes(id))
        .forEach((id) => {
          analytics.app.events.device.connected({
            ...baseContext,
            deviceId: id,
          });
        });

      // Disconnections
      presenceIdsMemo
        .filter((id) => !currentIds.includes(id))
        .forEach((id) => {
          analytics.app.events.device.disconnected({
            ...baseContext,
            deviceId: id,
          });
        });
    }

    return currentIds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room.state.presences]);

  let sceneMemo: Scene | null = null;

  sceneMemo = useMemo(() => {
    const isLastScene = room.episode.scenes?.slice(-1)[0].id === scene.id;

    if (user && (room.state.sceneIndex === 0 || isLastScene)) {
      analytics.app.events.scene.started(baseContext);
    }

    if (sceneMemo) {
      // analytics.app.events.scene.completed({
      //   ...baseContext,
      //   sceneId: sceneMemo.id,
      // });

      if (isLastScene) {
        analytics.app.events.room.episodeCompleted(baseContext);
      }
    }

    return scene;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene]);
};

export const usePrimaryDeviceState = (props: PrimaryDeviceProps) => {
  const { user } = useAuth0();
  const { room, scene, scenes, roomMethods } = props;
  const { sceneIndex } = room.state;
  const prevScene: Scene | null = scenes[sceneIndex - 1] || null;
  const { skipToScene, completeScene, exitRoom } = roomMethods;
  const allPlayers = room.state.presences.flatMap((p) => p.players);
  const hasInteractions = Boolean(scene.prompt || scene.quizChoices);
  const hasImagery = scene.media.some((m) =>
    ['video', 'image'].includes(m.type)
  );
  const contextBase = useMemo(() => {
    return {
      episodeId: room.episode.id,
      roomId: room.id,
      sceneId: scene.id,
      userId: user?.sub,
      userEmail: user?.email,
      userName: user?.name,
    };
  }, [room.episode.id, room.id, scene.id, user]);

  usePrimaryDeviceAnalytics(props);

  const maxPlayers = scene.directives.includes('ANSWER_AS_GROUP')
    ? 1
    : room.state.presences.reduce((maxPlayers, presence) => {
        return Math.max(maxPlayers, presence.players.length);
      }, 1);

  const handleCompleteScene = useCallback(() => {
    completeScene(sceneIndex);
  }, [completeScene, sceneIndex]);

  const handleSkipToScene = (targetScene: Scene) => {
    const targetSceneIndex = scenes.findIndex((s) => s.id === targetScene.id);

    if (targetSceneIndex >= 0 && scene.id !== targetScene.id) {
      // analytics.app.events.scene.skipped(contextBase);
      skipToScene(targetSceneIndex);
    }
  };

  const handleVideoStarted = useCallback(() => {
    // const media = scene.media[0];
    // if (user) {
    //   analytics.app.events.scene.video.segmentStarted({
    //     ...contextBase,
    //     videoId: media.url,
    //     timestamp: scene.videoStart || 0,
    //   });
    // }
  }, [contextBase, scene.media, scene.videoStart, user]);

  const handleVideoEnded = useCallback(() => {
    handleCompleteScene();
    // const media = scene.media[0];
    // analytics.app.events.scene.video.segmentCompleted({
    //   ...contextBase,
    //   videoId: media.url,
    //   timestamp: scene.videoEnd,
    // });
  }, [contextBase, handleCompleteScene, scene.media, scene.videoEnd]);

  const handleExit = () => {
    analytics.app.events.room.exited(contextBase);
    exitRoom();
  };

  // Add shortcut key for skipping scenes
  useEffect(() => {
    const handleSkipKey = ({ key }: KeyboardEvent) => {
      if (['Left', 'ArrowLeft'].includes(key)) {
        const prevSceneIndex = Math.max(0, sceneIndex - 1);
        skipToScene(prevSceneIndex);
      } else if (['Right', 'ArrowRight'].includes(key)) {
        const nextSceneIndex = Math.min(scenes.length - 1, sceneIndex + 1);
        skipToScene(nextSceneIndex);
      }
    };

    document.addEventListener('keydown', handleSkipKey);

    return () => {
      document.removeEventListener('keydown', handleSkipKey);
    };
  }, [sceneIndex, scenes.length, skipToScene]);

  return {
    ...props,
    prevScene,
    contextBase,
    allPlayers,
    maxPlayers,
    handleExit,
    hasImagery,
    hasInteractions,
    handleCompleteScene,
    handleSkipToScene,
    handleVideoStarted,
    handleVideoEnded,
  };
};

const Background = (props: { media: Media; opaque?: boolean }) => {
  const { media, opaque = false } = props;

  if (media.type === 'image') {
    return (
      <BackgroundContainer
        style={{
          opacity: opaque ? 1 : undefined,
          backgroundImage: `url(${media.url})`,
        }}
      />
    );
  }

  if (media.type === 'video') {
    return (
      <BackgroundContainer
        style={{
          opacity: opaque ? 1 : undefined,
        }}
      >
        <video src={media.url} loop muted autoPlay />
      </BackgroundContainer>
    );
  }

  return null;
};

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
  margin: 0;
  opacity: 0.25;

  video {
    margin: 0;
    position: relative;
    min-width: 100%;
    min-height: 100%;
  }
`;

const ExitButton = styled(Button)`
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-size: 14px !important;
  font-weight: 500;
  border-radius: 0.25em;
  background: #000;
  color: #fff !important;
  opacity: 0.25;
  transition: opacity 0.2s linear;
  z-index: 9999;

  &:hover {
    opacity: 1;
  }
`;

export default PrimaryDevice;
