import config from '../config';

export const CAMPAIGN_ID_STORAGE_KEY = 'campaignId';

export const campaignIdRegExp = new RegExp(`[?&]${config.campaign.idParam}=(?<id>[^&]+)`);

export const interceptCampaignId = (): boolean => {
  const { location } = window;
  const id = parseCampaignId();

  if (id) {
    setCampaignId(id);
    location.search = location.search.replace(campaignIdRegExp, '');
    return true;
  }

  return false;
};

export const parseCampaignId = (location: Location = window.location): string | null => {
  const match = location.search.match(campaignIdRegExp);
  return match?.groups?.id || null;
};

export const setCampaignId = (id: string) => {
  localStorage[CAMPAIGN_ID_STORAGE_KEY] = id;
};

export const getCampaignId = () => {
  return localStorage[CAMPAIGN_ID_STORAGE_KEY];
};