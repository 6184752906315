import React from 'react';
import styled from '@emotion/styled';
import { Room } from '../types';
import Avatar from './Avatar';

const LobbyParticipantsList = (props: { room: Room }) => {
  const { room } = props;

  const participantNodes = room.state.presences.flatMap((presence) => {
    return presence.players.map((player) => (
      <li key={player.id}>
        <Avatar animation="pop" name={player.name} src={player.avatarUrl} />
      </li>
    ));
  });

  while (participantNodes.length < room.episode.size.max) {
    const i = participantNodes.length;
    let className = 'placeholder';

    if (i >= room.episode.size.min) {
      className += ' optional';
    }

    participantNodes.push(
      <li key={`participant-${i}`} className={className}>
        <span className="number">{i + 1}</span>
      </li>
    );
  }

  return <Container>{participantNodes}</Container>;
};

const Container = styled.ul`
  max-width: 500px;
  display: flex;
  list-style: none;
  flex-wrap: wrap;

  .size-status {
    margin: 0.5em 0;
    font-size: 18px;
  }

  li {
    margin-right: 20px;
    margin-top: 15px;
    width: 100px;
    text-align: center;

    img {
      margin-bottom: 10px;
    }
  }

  .placeholder {
    .number {
      display: inline-block;
      padding-top: 20px;
      font-size: 48px;
      font-weight: 200;
      text-align: center;
      width: 100px;
      height: 100px;
      border: 2px solid #fff;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.1);
    }

    &.optional .number {
      border-style: dashed;
    }
  }
`;

export default LobbyParticipantsList;
