import styled from '@emotion/styled';
import React from 'react';
import QRCode from 'react-qr-code';
import { getRoomJoinPath } from '../lib/paths';

const RoomQR = (props: { roomCode: string }) => {
  const { protocol, host } = window.location;
  const joinUrl = `${protocol}//${host}${getRoomJoinPath(props.roomCode)}?qr`;

  return (
    <>
      <Container>
        <a href={joinUrl} target="join-room-page">
          <QRCode size={256} value={joinUrl} />
        </a>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: inline-block;
  padding: 10px;
  background: #fff;

  a {
    display: inline-block;
  }
`;

export default RoomQR;
