import { useSubscription, gql, useApolloClient } from '@apollo/client';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { getStoredPresenceId, setStoredPresenceId } from '../lib/presence';
import { computeRoomStateLogic, RoomStateLogic } from '../lib/room-logic';
import { Room, RoomState } from '../types';

export interface LiveRoomContextValue {
  room?: Room;
  logic?: RoomStateLogic;
  presenceId?: string;
  setPresenceId: (value: string) => void;
}

const LiveRoomContext = React.createContext<LiveRoomContextValue>({
  setPresenceId: () => {},
});

export const LiveRoomProvider = (
  props: PropsWithChildren<{
    room: Room;
  }>
) => {
  const [presenceId, setPresenceIdState] = useState(getStoredPresenceId());
  const roomState = useLiveRoomState(props.room, presenceId);

  const liveRoom: Room = {
    ...props.room,
    state: roomState,
  };

  const setPresenceId = (value: string) => {
    setStoredPresenceId(value);
    setPresenceIdState(value);
  };

  return (
    <LiveRoomContext.Provider
      value={{
        presenceId,
        setPresenceId,
        room: liveRoom,
        logic: computeRoomStateLogic({
          presenceId,
          room: liveRoom,
        }),
      }}
    >
      {props.children}
    </LiveRoomContext.Provider>
  );
};

export const useLiveRoom = () => {
  return useContext(LiveRoomContext) as {
    room: Room;
    logic: RoomStateLogic;
    presenceId?: string;
    setPresenceId: (value: string) => void;
  };
};

const useLiveRoomState = (init: Room, presenceId?: string) => {
  const [liveState, setLiveRoomState] = useState<undefined | RoomState>();

  useSubscription<
    { roomState: RoomState },
    { roomId: string; presenceId?: string }
  >(ROOM_STATE_CHANGED_SUB, {
    variables: {
      presenceId,
      roomId: init.id,
    },

    onSubscriptionData: ({ subscriptionData: { data } }) => {
      const newState = data?.roomState;

      if (!newState) {
        return;
      }

      setLiveRoomState({
        ...init.state,
        ...newState,
      });
    },
  });

  return liveState || init.state;
};

const ROOM_STATE_CHANGED_SUB = gql`
  subscription RoomStateChanged($roomId: ID!, $presenceId: ID) {
    roomState: roomStateChanged(
      input: { roomId: $roomId, presenceId: $presenceId }
    ) {
      phase
      sceneIndex
      presences {
        id
        isConnected
        isReadyForNextScene
        players {
          id
          name
          avatarUrl
        }
      }
      answers {
        answer
        sceneId
        playerId
      }
    }
  }
`;
