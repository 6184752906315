import styled from '@emotion/styled';

export default styled.input`
  margin-top: 15px;
  padding: 0.5em 1em;
  display: inline-block;
  max-width: 80%;
  background: #111;
  color: #fff;
  border-radius: 8px;
  border: 0;
  font-size: 21px;
  font-weight: 500;
  border: 2px solid #fff;
  cursor: pointer;
`;
