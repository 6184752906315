import { InMemoryCache, HttpLink, split, ApolloClient } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import config from './config';

export const cache = new InMemoryCache();

export const websocketLink = new WebSocketLink({
  uri: config.api.websocketUri,
  lazy: true,
  options: {
    reconnect: true,
  },
});

const httpLink = new HttpLink({
  uri: config.api.uri,
});

export const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  websocketLink,
  httpLink
);

export const apollo = new ApolloClient({
  cache,
  link: splitLink,
});
