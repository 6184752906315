import styled from '@emotion/styled';
import React from 'react';
import NoSleep from 'nosleep.js';
import {
  Media,
  Player,
  Presence,
  QuizChoice,
  Room,
  Scene,
  SceneDirective,
} from '../../types';
import Avatar from '../Avatar';
import Content from './Content';
import DeviceContainer from './DeviceContainer';
import Prompt from './Prompt';
import Quiz from './Quiz';
import Timer from './Timer';
import Vibration from '../Vibration';

const defaultIdleSrc = '/assets/idle.gif';

export interface SecondaryDeviceProps {
  scene: Scene;
  room: Room;
  idleBg: Media;
  presence: Presence;
  onAnswer: (playerIds: string[], answer: string) => any;
}

export default class SecondaryDevice extends React.Component<SecondaryDeviceProps> {
  // Lifecycle methods
  render() {
    if (this.isPrimaryDeviceShowingAnswers()) {
      return this.renderIdleScreen();
    }

    if (!this.hasInteractions()) {
      return this.renderNoInteractions();
    }

    if (this.everyoneAnswered()) {
      if (this.getScene().advanceWithButton) {
        return this.renderButtonWaitingScreen();
      }

      return this.hasSingleAnswer()
        ? this.renderSingleAnswer()
        : this.renderWaitingMessage();
    }

    return (
      <Container
        scene={this.props.scene}
        className="secondary-device interactive"
      >
        {this.getScene().advanceWithButton || this.renderTimer()}
        {this.renderInteractionAlert()}
        {this.renderInteractionHeader()}
        {this.renderContent()}
        {this.renderPrompt()}
        {this.renderQuiz()}
      </Container>
    );
  }

  componentDidMount() {
    const noSleep = new NoSleep();

    try {
      noSleep.enable();
    } catch (err) {
      document.addEventListener(
        'click',
        function enableNoSleep() {
          document.removeEventListener('click', enableNoSleep, false);
          noSleep.enable();
        },
        false
      );
    }

    document.onvisibilitychange = () => {
      if (document.visibilityState === 'visible') {
        window.location.reload();
      }
    };
  }

  // Accessors
  getRoom() {
    return this.props.room;
  }

  getScene() {
    return this.props.scene;
  }

  getTimerBaseDuration() {
    return this.getScene().timer || 0;
  }

  getDevicePlayers() {
    return this.props.presence.players;
  }

  getRoomPlayers() {
    return this.getRoom().state.presences.flatMap((p) => {
      return p.players;
    });
  }

  getRoomAnswers() {
    const scene = this.getScene();

    return (
      this.getRoom().state.answers?.filter((a) => a.sceneId === scene.id) || []
    );
  }

  getCurrentPlayer() {
    const players = this.getDevicePlayers();
    const answers = this.getRoomAnswers();
    const playersWhoAnswered = new Set(answers.map((a) => a.playerId));

    return players.find((player) => {
      return !playersWhoAnswered.has(player.id);
    });
  }

  getCurrentPlayerIndex() {
    const currentPlayer = this.getCurrentPlayer();
    const devicePlayers = this.getDevicePlayers();

    if (!currentPlayer) {
      return devicePlayers.length;
    }

    return devicePlayers.findIndex((p) => {
      return p.id === currentPlayer.id;
    });
  }

  getSingleAnswer() {
    const firstPlayer = this.getDevicePlayers()[0];

    return this.getRoomAnswers().find((a) => {
      return a.playerId === firstPlayer.id;
    })?.answer;
  }

  // Computed values
  getInteractiveDuration() {
    const baseDuration = this.getTimerBaseDuration();

    if (baseDuration === 0) {
      const { videoStart = 0, videoEnd = 0 } = this.getScene();
      return videoEnd - videoStart;
    }

    if (this.shouldAnswerAsGroup()) {
      return baseDuration;
    }

    return baseDuration * this.getRoomPlayers().length;
  }

  getOfflineActivityDuration() {
    return this.getTimerBaseDuration() * this.getRoomPlayers().length;
  }

  getMaxPlayersOnAnyDevice() {
    return this.getRoom().state.presences.reduce((maxPlayers, presence) => {
      return Math.max(maxPlayers, presence.players.length);
    }, 1);
  }

  // Logic methods
  isPrimaryDeviceShowingAnswers() {
    return this.getScene().showAnswers;
  }

  hasInteractions() {
    const scene = this.getScene();
    return Boolean(
      scene.prompt || scene.quizChoices || scene.advanceWithButton
    );
  }

  everyoneAnswered() {
    return this.getCurrentPlayerIndex() >= this.getDevicePlayers().length;
  }

  shouldAnswerAsGroup() {
    return this.hasDirective('ANSWER_AS_GROUP');
  }

  hasDirective(directive: SceneDirective) {
    return this.getScene().directives.includes(directive);
  }

  shouldCyclePlayers() {
    return (
      !this.shouldAnswerAsGroup() &&
      this.getDevicePlayers().length > 1 &&
      this.hasInteractions()
    );
  }

  hasSingleAnswer() {
    return this.shouldAnswerAsGroup() || this.getDevicePlayers().length === 1;
  }

  // Render methods
  renderInteractionAlert() {
    if (this.hasInteractions()) {
      return (
        <>
          <Vibration pattern={[200, 20, 200, 20, 100]} />
        </>
      );
    }

    return null;
  }

  renderIdleScreen() {
    return <Idle src={this.props.idleBg?.url || defaultIdleSrc} />;
  }

  renderNoInteractions() {
    const scene = this.getScene();

    return (
      <>
        {scene.timer && (
          <Timer duration={this.getInteractiveDuration()} onEnded={() => {}} />
        )}
        {scene.secondaryContent || scene.content ? (
          <DiscussionContainer>{this.renderContent()}</DiscussionContainer>
        ) : (
          this.renderIdleScreen()
        )}
      </>
    );
  }

  renderSingleAnswer() {
    return (
      <Container>
        <p>You answered:</p>
        <p className="player-answer">
          {this.getSingleAnswer() || '(No answer)'}
        </p>
      </Container>
    );
  }

  renderWaitingMessage() {
    return (
      <Container>
        <p>Waiting for the rest of the room to catch up...</p>
      </Container>
    );
  }

  renderTimer() {
    const duration = this.getInteractiveDuration();

    if (!duration && !this.getScene().directives.includes('LOOP_VIDEO')) {
      return null;
    }

    return <Timer duration={duration} onEnded={() => {}} />;
  }

  renderInteractionHeader() {
    const currentPlayer = this.getCurrentPlayer();

    if (!currentPlayer || this.getDevicePlayers().length === 1) {
      return null;
    }

    const players = this.getRoomPlayers();
    const totalPlayers = players.length;
    const playerIndex = this.getCurrentPlayerIndex();

    return this.shouldCyclePlayers() ? (
      <>
        {playerIndex === 0 && totalPlayers > 1 && <p>First Up:</p>}
        {playerIndex > 0 && <p>Pass to:</p>}
        <Avatar
          key={`avatar:${currentPlayer.id}`}
          name={currentPlayer.name}
          src={currentPlayer.avatarUrl}
        />
      </>
    ) : (
      <header>
        <ul className="avatars">
          {players.map((player) => (
            <li>
              <Avatar
                key={`avatar:${player.id}`}
                name={player.name}
                src={player.avatarUrl}
              />
            </li>
          ))}
        </ul>
        <h3>Work together here!</h3>
      </header>
    );
  }

  renderContent() {
    const scene = this.getScene();
    const content = scene.secondaryContent || scene.content;

    if (!content) {
      return null;
    }

    return (
      <Content
        onButtonPress={() => {
          const room = this.getRoom();
          const playerIds = this.getDevicePlayers().map((p) => p.id);
          const currentPlayer = this.getCurrentPlayer() as Player;
          const asGroup = this.shouldAnswerAsGroup();

          // analytics.app.events.scene.buttonPrompt.pressed({
          //   asGroup,
          //   roomId: room.id,
          //   episodeId: room.episode.id,
          //   deviceId: this.props.presence.id,
          //   sceneId: this.getScene().id,
          //   playerId: asGroup ? 'GROUP' : currentPlayer.id,
          // });

          this.props.onAnswer(playerIds, 'READY');
        }}
        key={`content-${scene.id}`}
        source={content}
      />
    );
  }

  renderButtonWaitingScreen() {
    return (
      <ButtonWaitingScreen>
        <h1>We are waiting on other players to finish.</h1>
        <p>
          Every player will need to click the button from their device to
          continue.
        </p>
      </ButtonWaitingScreen>
    );
  }

  renderQuiz() {
    const scene = this.getScene();
    const currentPlayer = this.getCurrentPlayer();

    if (!scene.quizChoices || !currentPlayer) {
      return null;
    }

    return (
      <Quiz
        key={`quiz:${scene.id}:player:${currentPlayer?.id || ''}`}
        choices={scene.quizChoices || []}
        onAnswer={this.handleQuizChoices}
        allowMulti={scene.directives.includes('MULTI_SELECT')}
      />
    );
  }

  renderPrompt() {
    const scene = this.getScene();
    const currentPlayer = this.getCurrentPlayer();

    if (!scene.prompt || !currentPlayer) {
      return null;
    }

    return (
      <Prompt
        onAnswer={this.handlePromptAnswer}
        key={`prompt:${scene.id}:player:${currentPlayer?.id || ''}`}
        {...scene.prompt}
      />
    );
  }

  // Event handlers
  handleQuizChoices = (choices: QuizChoice[]) => {
    // const room = this.getRoom();
    const answer = choices.map((c) => c.label).join(', ');
    const currentPlayer = this.getCurrentPlayer() as Player;
    const asGroup = this.shouldAnswerAsGroup();

    const playerIds = asGroup
      ? this.getDevicePlayers().map((p) => p.id)
      : [currentPlayer.id];

    // analytics.app.events.scene.multipleChoice.answered({
    //   answer,
    //   asGroup: asGroup || false,
    //   roomId: room.id,
    //   deviceId: this.props.presence.id,
    //   episodeId: room.episode.id,
    //   sceneId: this.getScene().id,
    //   playerId: asGroup ? 'GROUP' : currentPlayer.id,
    // });

    this.props.onAnswer(playerIds, answer);
  };

  handlePromptAnswer = (answer: string) => {
    // const room = this.getRoom();
    // const currentPlayer = this.getCurrentPlayer() as Player;
    // const asGroup = this.shouldAnswerAsGroup();

    // analytics.app.events.scene.prompt.answered({
    //   answer,
    //   asGroup,
    //   roomId: room.id,
    //   deviceId: this.props.presence.id,
    //   episodeId: room.episode.id,
    //   sceneId: this.getScene().id,
    //   playerId: asGroup ? 'GROUP' : currentPlayer.id,
    // });

    this.handleAnswer(answer);
  };

  handleAnswer(answer: string) {
    const currentPlayer = this.getCurrentPlayer() as Player;

    const playerIds = this.shouldAnswerAsGroup()
      ? this.getDevicePlayers().map((p) => p.id)
      : [currentPlayer.id];

    this.props.onAnswer(playerIds, answer);
  }
}

const ButtonWaitingScreen = styled.div`
  padding: 0 10px;
`;

const Idle = styled.div<{ src?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #72b5e2;
  background-position: top center;
`;

export const Container = styled(DeviceContainer)<{
  children: any;
  scene?: Scene;
}>`
  padding: 0 15px;
  font-size: clamp(12px, 3vh, 21px);
  width: 100%;

  @keyframes flash {
    25%,
    75% {
      background: #ccc;
    }

    0%,
    50%,
    100% {
      background: none;
    }
  }

  &.interactive {
    --scene-id: '${(props) => props.scene?.id}';
    animation-name: flash;
    animation-iteration-count: 1;
    animation-duration: 0.5s;
    background: #76b8ea;
  }

  p {
    font-size: 21px;
    line-height: 1.5;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.25em;
    color: #ff0;
  }

  h3 {
    font-size: 1.15em;
  }

  .avatars {
    width: 100%;
    list-style: none;
    text-align: center;

    li {
      padding: 5px;
      display: inline-block;
    }
  }
`;

export const DiscussionContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f2c044;
  display: flex;
  color: #111;
`;
