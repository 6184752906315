import styled from '@emotion/styled';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;

  .media {
    img,
    video {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
      border-radius: 2vw;
      border: 1vw solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.2);
      max-height: 85vh;
      overflow: hidden;
    }
  }
`;
