import React, { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import Sound from './Sound';

const soundSrc = '/assets/bell.mp3';

export default function PopupMessage(props: PropsWithChildren<{}>) {
  return (
    <>
      <Sound url={soundSrc} />
      <Container>{props.children}</Container>
    </>
  );
}

const Container = styled.div`
  position: fixed;
  transform: translateX(-50%) translateY(-100%);
  left: 50%;
  top: 0;
  max-width: 400px;
  padding: 15px;
  background: #fff;
  border-radius: 5px;
  color: #111;
  animation: 3s 1 forwards peek;
  font-size: 21px;

  @keyframes peek {
    0%,
    100% {
      transform: translateX(-50%) translateY(-100%);
      opacity: 0;
    }

    10%,
    90% {
      transform: translateX(-50%) translateY(15px);
      opacity: 1;
    }
  }
`;
